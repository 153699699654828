export enum ToastrTypesEnum {
  error = 'error',
  warning = 'warning',
}

export enum LoadingStatusEnum {
  hidden = 'hidden',
  loading = 'loading',
  loadingEnd = 'loadingEnd',
}

export enum ServiceStatusEnum {
  initial,
  unavailable,
  success
}
